import React, { useRef } from "react"
import { detectMob, ClientOnly } from "@src/Helper"
import NewFeaturesLayout from "./NewFeaturesLayout"
import Sidenav from "@components/utils/Sidenav"

const NewFeatures = () => {
  // Hooks
  const backdrop = useRef(null)

  // Custom Function
  const handleCloseBackdrop = () => {
    if (backdrop.current) {
      if (backdrop.current.classList.contains("backdrop--show")) {
        const sidenav = document.querySelector(".sidenav")

        backdrop.current.classList.remove("backdrop--show")
        sidenav.classList.add("sidenav--expand")
        sidenav.classList.remove("sidenav--expanded")

        document.querySelector("body").classList.remove("overflow-hidden")
        document.querySelector(".sidenav").style.zIndex = 996
      }
    }
  }

  // JSX
  if (detectMob()) {
    return (
      <>
        <div id="m-newFeatures" className="new-features pad-t-90">
          <div className="row mx-0">
            <div className="col-12">
              <div className="new-features__container p-0">
                <ClientOnly>
                  <NewFeaturesLayout />
                </ClientOnly>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="new-features">
        <div
          ref={backdrop}
          className="backdrop"
          onClick={() => handleCloseBackdrop()}
        ></div>
        <Sidenav />
        <div className="new-features__container">
          <ClientOnly>
            <NewFeaturesLayout />
          </ClientOnly>
        </div>
      </div>
    </>
  )
}

export default NewFeatures
