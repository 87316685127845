import * as React from "react"
import NewFeatures from "@components/NewFeatures/NewFeatures"
import Seo from "@components/seo"

const NewFeaturesPage = () => (
  <>
    <Seo title="New Features" />
    <NewFeatures />
  </>
)

export default NewFeaturesPage
