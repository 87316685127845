import React, { useState } from "react"
import { newFeaturesPipehome, newFeaturesSociopipe } from "./new-features-data"
import NewFeaturesTabBar from "./NewFeaturesTabBar"

const NewFeaturesLayout = () => {
  // Hooks
  const [activeTab, setActiveTab] = useState(0)

  // JSX
  return (
    <section className="new-features__layout">
      <NewFeaturesTabBar setActiveTab={setActiveTab} activeTab={activeTab} />
      {activeTab === 0 ? (
        <div className="new-features__sociopipe">
          <h2 className="new-features__sociopipe__web heading-5">Sociopipe</h2>
          <div className="new-features__sociopipe__list">
            {newFeaturesSociopipe.map((data, key) => (
              <div key={key} className="new-features__sociopipe__item mb-4">
                <h3 className="new-features__sociopipe__item-date body">
                  {data.date}
                </h3>
                <ul>
                  {data.details.map((detail, key) => (
                    <li key={key}>
                      <h4 className="new-features__sociopipe__item-title body">
                        {detail.title}
                      </h4>
                      {detail?.video && (
                        <video
                          src={detail.video}
                          className="new-features__sociopipe__item-video"
                          playsInline
                          autoPlay
                          loop
                          muted
                        />
                      )}
                      <h5 className="new-features__sociopipe__item-description body">
                        {detail.description}
                      </h5>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="new-features__pipehome">
          <h2 className="new-features__pipehome__web heading-5">Pipehome</h2>
          <div className="new-features__pipehome__list">
            {newFeaturesPipehome.map((data, key) => (
              <div key={key} className="new-features__pipehome__item">
                <h3 className="new-features__pipehome__item-date body">
                  {data.date}
                </h3>
                <ul>
                  {data.details.map((detail, key) => (
                    <li key={key}>
                      <h4 className="new-features__pipehome__item-title body">
                        {detail.title}
                      </h4>
                      {detail?.video && (
                        <video
                          src={detail.video}
                          className="new-features__pipehome__item-video"
                          playsInline
                          autoPlay
                          loop
                          muted
                        />
                      )}
                      <h5 className="new-features__pipehome__item-description body">
                        {detail.description}
                      </h5>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default NewFeaturesLayout
