import React from "react"
import { Link } from "gatsby"

const NewFeaturesTabBar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="new-features__layout__tab-bar">
      <Link
        className={`new-features__layout__tab-bar__link body ${
          activeTab === 0 ? "active" : ""
        }`}
        to="/new-features#sociopipe"
        onClick={() => setActiveTab(0)}
      >
        Sociopipe
      </Link>
      <Link
        className={`new-features__layout__tab-bar__link body ${
          activeTab === 1 ? "active" : ""
        }`}
        to="/new-features#pipehome"
        onClick={() => setActiveTab(1)}
      >
        Pipehome
      </Link>
    </div>
  )
}

export default NewFeaturesTabBar
