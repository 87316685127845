import { detectMob } from "@src/Helper"
import {
  CustomBackground,
  CustomButton,
  CustomPipehome,
  CustomFont,
  CustomFeed,
  CustomCategory,
  NewDashboard1,
  NewDashboard2,
  NewDashboard3,
  NewDashboardMob1,
  NewDashboardMob2,
  NewDashboardMob3,
} from "./index"

export const newFeaturesSociopipe = [
  {
    date: "27 Januari 2022",
    details: [
      {
        title: "Tampilan Dasbor Baru",
        description:
          "Pengalaman pengguna adalah hal penting. Itulah mengapa kami di Sociopipe merancang tampilan yang bakal semakin memudahkan kamu dalam bereksplorasi. Semua perubahan tampilan kini sudah bisa kamu lihat secara langsung dan real time!",
        video: detectMob() ? NewDashboardMob1 : NewDashboard1,
      },
      {
        title: "Ubah konten kamu dengan mudah!",
        description:
          "Ubah konten anti bingung. Kamu bakal makin mudah menyesuaikan isi konten Pipehome kamu karena kamu gak usah takut salah edit bagian Pipehome kamu lagi karena kami menyediakan pengalaman editing Pipehome yang belum pernah ada sebelumnya.",
        video: detectMob() ? NewDashboardMob2 : NewDashboard2,
      },
      {
        title: "Ubah tampilan kamu dengan mudah juga!",
        description:
          "Bukan hanya bongkar pasang konten yang mudah, kini mengganti-ganti tampilan Pipehome juga mudahnya tiada dua karena sekali lagi tampilan baru dasbor kami menyediakan pengalaman editing Pipehome yang belum pernah ada sebelumnya.",
        video: detectMob() ? NewDashboardMob3 : NewDashboard3,
      },
    ],
  },
  {
    date: "15 November 2021",
    details: [
      {
        title: "Edit Background",
        description:
          "We hear you! Pipehome mu kini bisa diganti background sesuai selera kamu lho! Tapi gak sampai disitu, kamu bisa mengkombinasikan Tema yang kamu pilih dengan background yang ingin kamu pasang, full set customization is not a dream anymore!",
        video: CustomBackground,
      },
      {
        title: "Edit Button",
        description:
          "Power mu gak berhenti hanya sampai mengganti background, kini button juga bisa kamu ganti bentuk dan warna-warnanya sesuai selera dan sesuaikan dengan background/tema yang kamu pilih.",
        video: CustomButton,
      },
      {
        title: "Edit Warna Font",
        description:
          "Udah bisa ganti background dan button rasanya tanggung banget kalau tulisan gak bisa kamu utak-atik juga warnanya ya? Makanya kami ngerti banget kok kebutuhan kamu, mengganti warna tulisan juga sekarang jadi mudah banget lho!",
        video: CustomFont,
      },
      {
        title: "Edit Feed",
        description:
          "Feed adalah bagian penting dari Pipehome jadi rasanya gak mungkin kalau warnanya gak bisa kamu sesuaikan dengan kemauan kamu, sekarang kami juga hadirkan fitur penyesuaian warna feed supaya Pipehome kamu makin kece!",
        video: CustomFeed,
      },
      {
        title: "Kategori Pipehome",
        description:
          "Data ini bertujuan untuk mengetahui niche para pengguna Sociopipe.",
        video: CustomCategory,
      },
    ],
  },
]

export const newFeaturesPipehome = [
  {
    date: "15 November 2021",
    details: [
      {
        title: "Tampilan Baru",
        description:
          "New layout is here! Tampilan yang lebih fresh, modern dan minimalis sekarang jadi pemandangan yang bisa dinikmati orang-orang yang mengunjungi Pipehome kamu…",
        video: CustomPipehome,
      },
    ],
  },
]
